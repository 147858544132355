import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Dropdown, Icon, Responsive, Modal } from 'semantic-ui-react';
import './UserAuth.scss';
import { lookupsApi, usersApi } from '../../api/api';

export default function Signup() {
  const router = useHistory();
  const [userCreated, setUserCreated] = useState(false);
  const [touched, setTouched] = useState({});
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    fullname: '',
    password: '',
    passwordConfirm: '',
    phone: '',
    email: '',
    jobTitle: '',
    image: '',
    role: 28,
    verified: false,
    // passwordNeverExpires: false,
  });
  const [errors, setErrors] = useState({
    username: null,
    fullname: null,
    password: null,
    passwordConfirm: null,
    phone: null,
    email: null,
    image: null,
    usernameBackendError: null,
    phoneBackendError: null,
    emailBackendError: null,
    passwordBackendError: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const res = await lookupsApi.getGroup(2);
      const options = res.data.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setJobTitleOptions(options);
      setFormData((prevData) => ({ ...prevData, jobTitle: options[0].key }));
    };

    fetchData();
  }, []);
  useEffect(() => {
    validation();
  }, [formData]);

  const selectionChangeHandler = (e, data) => {
    setFormData({ ...formData, jobtitle: data.value });    
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const validation = () => {
    const newErrors = {};

    if (!formData.username) {
      newErrors.username = 'الرجاء ادخال اسم المستخدم';
    } else if (formData.username.length < 3) {
      newErrors.username = 'اسم المستخدم يجب ان يكون 3 احرف على الاقل';
    } else if (/[\u0621-\u064A\s]+/.test(formData.username)) {
      newErrors.username = 'اسم المستخدم يجب ان يحتوي على احرف انجليزية وارقام و رموز فقط';
    }

    if (!formData.fullname) {
      newErrors.fullname = 'الرجاء ادخال الاسم الكامل';
    } else if (formData.fullname.length < 3) {
      newErrors.fullname = 'الاسم الكامل يجب ان يكون 3 احرف على الاقل';
    }

    if (!formData.password) {
      newErrors.password = 'الرجاء ادخال كلمة السر';
    } else if (formData.password.length < 3) {
      newErrors.password = 'كلمة السر يجب ان تكون 3 احرف على الاقل';
    }

    if (!formData.passwordConfirm) {
      newErrors.passwordConfirm = 'الرجاء ادخال تأكيد كلمة السر';
    } else if (formData.password !== formData.passwordConfirm) {
      newErrors.passwordConfirm = 'كلمة السر غير متطابقة';
    }

    if (!formData.email) {
      newErrors.email = 'الرجاء ادخال الايميل';
    } else if (!formData.email.includes('@')) {
      newErrors.email = 'الرجاء ادخال ايميل صحيح';
    }

    if (!formData.phone) {
      newErrors.phone = 'الرجاء ادخال رقم الهاتف';
    } else if (formData.phone.length < 7 || formData.phone.length > 15 || !/^[0-9]+$/.test(formData.phone)) {
      newErrors.phone = 'الرجاء ادخال رقم هاتف صحيح';
    }

    if (formData.image && !['png', 'jpg', 'jpeg'].includes(formData.image.split('.').pop().toLowerCase())) {
      newErrors.image = 'الرجاء اختيار صورة بامتداد png او jpg او jpeg';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setTouched({
      username: true,
      fullname: true,
      password: true,
      passwordConfirm: true,
      phone: true,
      email: true,
    });
    if (!validation()) {
      setIsSubmitting(false);
      return;
    }

    try {
      await usersApi.postUser(formData);
      setUserCreated(true);
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          usernameBackendError: e.response.data.username ? 'اسم المستخدم مكرر' : null,
          phoneBackendError: e.response.data.phone ? 'رقم الهاتف مكرر' : null,
          emailBackendError: e.response.data.email ? 'الايميل مكرر' : null,
          passwordBackendError: e.response.data.password ? e.response.data.password : null,
        }));
      } else {
        console.error('Unexpected error:', e);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='login-bg'>
      <Modal
        content='تم ارسال طلب انشاء الحساب - سيتم تأكيد حسابكم في أقرب وقت'
        actions={[{ content: 'حسناً', color: 'blue' }]}
        open={userCreated}
        onClose={router.goBack}
      />

      <div className='signup-card'>
        <div className='signup-contents form-margin'>
          <h2 style={{ marginBottom: '2rem' }}>تسجيل حساب جديد</h2>
          <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
            <Form.Group widths={2}>
              <Form.Field>
                <label>اسم المستخدم</label>
                <Form.Input
                  icon='user'
                  value={formData['username']}
                  name='username'
                  onChange={onChangeHandler}
                  error={
                    touched.username && (errors.usernameBackendError || errors.username)
                      ? errors.usernameBackendError || errors.username
                      : null
                  }
                />
              </Form.Field>

              <Form.Field>
                <label>الاسم الكامل</label>
                <Form.Input
                  icon='user circle'
                  value={formData['fullname']}
                  name='fullname'
                  onChange={onChangeHandler}
                  error={touched.fullname && errors.fullname ? errors.fullname : null}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Field>
                <label>كلمة السر</label>
                <Form.Input
                  icon='lock'
                  type='password'
                  value={formData['password']}
                  name='password'
                  onChange={onChangeHandler}
                  error={
                    touched.password && (errors.password || errors.passwordBackendError)
                      ? errors.password || errors.passwordBackendError
                      : null
                  }
                />
              </Form.Field>

              <Form.Field>
                <label>تأكيد كلمة السر</label>
                <Form.Input
                  icon='lock'
                  type='password'
                  value={formData['passwordConfirm']}
                  name='passwordConfirm'
                  onChange={onChangeHandler}
                  error={
                    touched.passwordConfirm && (errors.passwordConfirm || errors.passwordConfirm)
                      ? errors.passwordConfirm || errors.passwordConfirm
                      : null
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Field>
                <label>الهاتف</label>
                <Form.Input
                  icon='phone'
                  value={formData['phone']}
                  name='phone'
                  onChange={onChangeHandler}
                  error={
                    touched.phone && (errors.phone || errors.phoneBackendError)
                      ? errors.phoneBackendError || errors.phone
                      : null
                  }
                />
              </Form.Field>

              <Form.Field>
                <label>الايميل</label>
                <Form.Input
                  icon='mail'
                  value={formData['email']}
                  name='email'
                  onChange={onChangeHandler}
                  error={
                    touched.email && (errors.email || errors.emailBackendError)
                      ? errors.emailBackendError || errors.email
                      : null
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>المسمى الوظيفي</label>
              <Dropdown
                placeholder='اختر المسمى الوظيفي'
                fluid
                selection
                search
                value={formData['jobTitle']}
                options={jobTitleOptions}
                onChange={selectionChangeHandler}
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>

            <Form.Field>
              <label>الصورة</label>
              <Form.Input
                type='file'
                name='image'
                onChange={onChangeHandler}
                accept='.jpg,.jpeg,.png'
                error={touched.image && errors.image ? errors.image : null}
              />
            </Form.Field>

            <Responsive minWidth={768}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginLeft: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
            <Responsive maxWidth={767}>
              <div style={{ marginTop: '2rem' }}>
                <Button
                  icon
                  fluid
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className='blue-button'
                  labelPosition='right'
                  style={{ marginBottom: '1rem' }}
                >
                  <Icon name='save' className='blue-buton-icon' />
                  حفظ
                </Button>

                <Button
                  fluid
                  icon='chevron right'
                  labelPosition='right'
                  content='رجوع'
                  onClick={(e) => {
                    e.preventDefault();
                    router.goBack();
                  }}
                />
              </div>
            </Responsive>
          </Form>
        </div>
      </div>
    </div>
  );
}
