import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message, Button, Image } from 'semantic-ui-react';
import './UserAuth.scss';
import logo from './../../assets/images/logo.png';
import { authApi, usersApi } from '../../api/api';
import { saveState } from '../../api/sessionStorage';
import { useDispatch } from 'react-redux';
import { userAuthActions } from './../../redux/actions/actionCreator';

export default function Login() {
  const router = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    UserName: '',
    Password: '',
    NewPassword: '',
    ConfirmPassword: '',
  });

  const [validationErrors, setValidationErrors] = useState({
    UserName: null,
    Password: null,
    NewPassword: null,
    ConfirmPassword: null,
  });

  const [touched, setTouched] = useState({
    UserName: false,
    Password: false,
    NewPassword: false,
    ConfirmPassword: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isChangePasswordFormShown, setIsChangePasswordFormShown] = useState(false);

  const [error, setError] = useState('');

  const validateFormOnPasswordChange = () => {
    const errors = {
      UserName: null,
      Password: null,
      NewPassword: null,
    };

    if (!formData.NewPassword) {
      errors.NewPassword = 'الرجاء ادخال كلمة السر';
    } else {
      const passwordLength = formData.NewPassword.length;
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).*$/;

      if (passwordLength < 8 || passwordLength > 64) {
        errors.NewPassword = 'يجب أن تكون كلمة السر بين 8 و 64 خانة على الأقل';
      } else if (!passwordPattern.test(formData.NewPassword)) {
        errors.NewPassword = ' يجب أن تحتوي كلمة السر على حرف صغير ، وحرف كبير، ورمز خاص، ورقم';
      } else if (formData.NewPassword.toLowerCase().includes(formData.UserName.toLowerCase())) {
        errors.NewPassword = 'لا يمكن أن تحتوي كلمة السر على اسم المستخدم';
      } else if (formData.NewPassword !== formData.ConfirmPassword) {
        errors.NewPassword = 'كلمة السر غير متطابقة';
      }
      setError(errors.NewPassword);
    }

    setValidationErrors(errors);
    if (errors.NewPassword || errors.ConfirmPassword) {
      return false;
    }
    return true;
  };

  const validateFormOnLogin = () => {
    const errors = {
      UserName: null,
      Password: null,
    };

    //UserName VALIDATION
    if (!formData.UserName) {
      errors.UserName = 'الرجاء ادخال اسم المستخدم';
    }

    //Password VALIDATION
    if (!formData.Password) {
      errors.Password = 'الرجاء ادخال كلمة السر';
    }

    setValidationErrors(errors);

    return !validationErrors.UserName && !validationErrors.Password;
  };

  useEffect(() => {
    saveState(null);
  }, []);

  useEffect(() => {
    if (isChangePasswordFormShown) {
      validateFormOnPasswordChange();
    }
  }, [formData.NewPassword, formData.ConfirmPassword]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      UserName: true,
      Password: true,
    });

    if (validateFormOnLogin()) {
      await login(formData);
    }

    setIsSubmitting(false);
  };

  const login = async (formData) => {
    try {
      const credentials = await authApi.login(formData);
      if (credentials.status === 200) {
        if (credentials.data.token === 'unverified') {
          setError('لم يتم تفعيل الحساب بعد');
          setIsSubmitting(false);
        } else if (credentials.data.token === 'notlinked') {
          setError('لم يتم ربط الحساب بعملاء');
          setIsSubmitting(false);
        } else {
          dispatch(userAuthActions.login(credentials.data));
          return router.push('/home');
        }
      } else {
        setError('معلومات الدخول غير صحيحة');
        setIsSubmitting(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) { 
        setError(error.response.data.message);

        if (error.response.data.passwordMustBeChanged) {
          setIsChangePasswordFormShown(true);
        }
      } else {
        setError('حدث خطأ يرجى المحاولة مجدداً');
      }
    }
  };

  const changePasswordWhenExpire = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTouched({
      UserName: true,
      Password: true,
    });

    if (validateFormOnPasswordChange()) {
      try {
        await usersApi.changePasswordWhenExpired({
          username: formData.UserName,
          password: formData.Password,
          newPassword: formData.NewPassword,
        });

        await login({
          UserName: formData.UserName,
          Password: formData.NewPassword,
        });
      } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 400)) {
          setError(error.response.data.message);
        }
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='login-bg'>
      <div className='login-card'>
        <div className='login-header'>
          <Image src={logo} avatar className='logo' />
          <p style={{ fontWeight: 'bold' }}>الشركة العامة للحاسبات والالكترونيات</p>
        </div>

        {!isChangePasswordFormShown ? (
          <Form fluid='true' error autoComplete='off' style={{ margin: '1rem' }} onSubmit={onSubmitHandler}>
            <Form.Field>
              <label>اسم المستخدم</label>
              <Form.Input
                icon='user'
                value={formData['UserName']}
                name='UserName'
                onChange={onChangeHandler}
                error={touched.UserName && validationErrors.UserName}
              />
            </Form.Field>

            <Form.Field>
              <label>كلمة السر</label>
              <Form.Input
                icon='lock'
                type='Password'
                value={formData['Password']}
                name='Password'
                onChange={onChangeHandler}
                error={touched.Password && validationErrors.Password}
              />
            </Form.Field>

            {error && <Message error content={error} />}
            <Button
              fluid
              type='submit'
              className='blue-button'
              loading={isSubmitting}
              disabled={isSubmitting}
              style={{ margin: '1rem 0' }}
            >
              تسجيل الدخول
            </Button>
            <Button
              fluid
              type='button'
              className='button-style'
              onClick={() => router.push('/signup')}
              style={{ margin: '1rem 0' }}
            >
              انشاء حساب جديد
            </Button>
          </Form>
        ) : (
          <Form fluid='true' error autoComplete='off' style={{ margin: '1rem' }} onSubmit={changePasswordWhenExpire}>
            <Form.Field>
              <label>كلمة السر الجديدة</label>
              <Form.Input
                icon='lock'
                type='password'
                value={formData['NewPassword']}
                name='NewPassword'
                onChange={onChangeHandler}
              />
            </Form.Field>
            <Form.Field>
              <label>تأكيد من كلمة السر الجديدة</label>
              <Form.Input
                icon='lock'
                type='password'
                value={formData['ConfirmPassword']}
                name='ConfirmPassword'
                onChange={onChangeHandler}
              />
            </Form.Field>
            {error && <Message error content={error} />}
            <Button
              fluid
              type='submit'
              className='blue-button'
              loading={isSubmitting}
              disabled={isSubmitting}
              style={{ margin: '1rem 0' }}
            >
              تعديل كلمة السر
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
}
