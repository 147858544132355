import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Message, Button, Dropdown, Icon, Responsive, Image, Tab, Checkbox, Popup } from 'semantic-ui-react';
import { customersApi, lookupsApi, rolesApi, usersApi, departmentsApi } from './../../../api/api';
import PageCardContainer from '../../Layout/PageCardContainer';
import Webcam from 'react-webcam';
import permissions, { hasPermission } from '../../../helpers/permissions';
import { groupBy } from 'lodash';

export default function EditUserForm() {
  const router = useHistory();
  const location = useLocation();
  const { id } = location.state;
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    userName: '',
    fullName: '',
    password: '',
    passwordConfirm: '',
    phone: '',
    email: '',
    jobTitle: '',
    role: '',
    customerId: null,
    image: '',
    passwordNeverExpires: false,
  });

  const [validationErrors, setValidationErrors] = useState({
    userName: null,
    fullName: null,
    phone: null,
    email: null,
    image: null,
  });

  const [touched, setTouched] = useState({
    userName: false,
    fullName: false,
    phone: false,
    email: false,
  });

  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [customerIdOptions, setCustomerIdOptions] = useState([]);
  const [departmentsIdOptions, setDepartmentsIdOptions] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //get data for editing
        const { data: userData } = await usersApi.getOneUser(id);

        //get lookups for jobTitles
        const jobTitles = await lookupsApi.getGroup(2);
        //loop over the data and store in an array of objects for select options
        let options1 = [];
        for (let item of jobTitles.data) {
          options1.push({ key: item.id, text: item.name, value: item.id });
        }
        setJobTitleOptions(options1);

        //get roles
        const roles = await rolesApi.getAllRoles();
        let options2 = [];
        for (let item of roles.data.data) {
          options2.push({ key: item.id, text: item.name, value: item.id });
        }
        setRolesOptions(options2);

        //get customers
        const { data: customers } = await customersApi.getCustomersDropDown();
        for (let result of customers) {
          if (userData.customerIds.includes(result.id)) result.isChecked = true;
        }
        setCustomerIdOptions(customers);

        const { data: departments } = await departmentsApi.getDepartmentsDropDown();
        const mappedDepartments = departments.map((dep) => ({
          ...dep,
          isChecked: userData.departmentIds.includes(dep.id),
        }));
        setDepartmentsIdOptions(mappedDepartments);
        setFormData(userData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        alert('حدث خطأ أثناء تحميل البيانات');
        console.log('Error fetching user form data...', err);
        router.goBack();
      }
    };

    fetchData();
  }, []);

  const validation = () => {
    const errors = {
      userName: null,
      fullName: null,
      phone: null,
      email: null,
      image: null,
    };

    //USERNAME VALIDATION
    if (!formData.userName) {
      errors.userName = 'الرجاء ادخال اسم المستخدم';
    } else if (formData.userName.length < 3) {
      errors.userName = 'اسم المستخدم يجب ان يكون 3 احرف على الاقل';
    } else if (!/^[a-zA-Z][a-zA-Z0-9_\.]+$/.test(formData.userName)) {
      errors.userName = 'إسم المستخدم يجب أن يحتوي فقط على أحرف إنجليزية أو أرقام أو . أو _';
    }

    //FULLNAME VALIDATION
    if (!formData.fullName) {
      errors.fullName = 'الرجاء ادخال الاسم الكامل';
    } else if (formData.fullName.length < 3) {
      errors.fullName = 'الاسم الكامل يجب ان يكون 3 احرف على الاقل';
    }

    //EMAIL VALIDATION
    if (!formData.email) {
      errors.email = 'الرجاء ادخال الايميل';
    } else if (!formData.email.includes('@') || /[\u0621-\u064A\s]+/.test(formData.email)) {
      errors.email = 'الرجاء ادخال ايميل صحيح';
    }

    //PHONE VALIDATION
    if (!formData.phone) {
      errors.phone = 'الرجاء ادخال رقم الهاتف';
    } else if (formData.phone.length < 7 || formData.phone.length > 15 || !/^[0-9]+$/.test(formData.phone)) {
      errors.phone = 'الرجاء ادخال رقم هاتف صحيح';
    }

    setValidationErrors(errors);

    if (
      validationErrors.userName ||
      validationErrors.fullName ||
      validationErrors.phone ||
      validationErrors.email ||
      validationErrors.image
    )
      return false;
    else return true;
  };

  useEffect(() => {
    validation();
  }, [formData]);

  const onChangeHandler = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const jobSelectionChangeHandler = (e, data) => {
    setFormData({ ...formData, jobTitle: data.value });
  };

  const roleSelectionChangeHandler = (e, data) => {
    setFormData({ ...formData, role: data.value });
  };

  const fileChangeHandler = (e) => {
    const fileUpload = document.getElementById('fileUpload');
    if (fileUpload.files.length) {
      const extension = fileUpload.value.split('.').pop().toLowerCase();
      if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
        setErrors([]);
        const uploadedImage = fileUpload.files[0];
        const reader = new FileReader();
        reader.onload = function (upload) {
          setFormData({ ...formData, image: `data:image/jpeg;base64, ${btoa(upload.target.result)}` });
        };
        reader.readAsBinaryString(uploadedImage);
      } else {
        setErrors([
          {
            key: 1000,
            message: 'الرجاء اختيار صورة بامتداد png او jpg او jpeg',
          },
        ]);
      }
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let errorsList = [];

    setTouched({
      userName: true,
      fullName: true,
      phone: true,
      email: true,
    });

    // if (!customerIdOptions.find((x) => x.isChecked === true)) {
    //   errorsList.push({ key: 0, message: 'يجب اختيار عميل واحد على الاقل' });
    // }

    if (validation() && !errorsList.length) {
      let customerIds = [];
      for (const c of customerIdOptions) {
        if (c.isChecked) customerIds.push(c.id);
      }

      let departmentsIds = [];
      for (const d of departmentsIdOptions) {
        if (d.isChecked) departmentsIds.push(d.id);
      }

      try {
        await usersApi.editUser(id, {
          ...formData,
          customerIds: customerIds,
          departmentsIds: departmentsIds,
        });
        router.goBack();
      } catch (e) {
        setIsSubmitting(false);
        console.log('Error editing user...', e);
        if (e.response && e.response.status === 400 && e.response.data) {
          if (e.response.data.username) errorsList.push({ key: 0, message: 'اسم المستخدم مكرر' });
          if (e.response.data.phone) errorsList.push({ key: 1, message: 'رقم الهاتف مكرر' });
          if (e.response.data.email) errorsList.push({ key: 2, message: 'الايميل مكرر' });
          setErrors(errorsList);
        } else {
          console.log('Error editing user...', e);
          setErrors([{ key: 0, message: 'خطأ اثناء الحفظ' }]);
        }
      }
    } else {
      setErrors(errorsList);
      setIsSubmitting(false);
    }
  };

  // capture camera image
  const webcamRef = React.useRef(null);

  const cameraChangeHandler = (e) => {
    e.preventDefault();
    const imageSrc = webcamRef.current.getScreenshot();
    setFormData({ ...formData, image: imageSrc });
  };

  const panes = [
    {
      menuItem: 'تحميل صورة من الجهاز',
      render: () => <Tab.Pane>{imageFromDevice()}</Tab.Pane>,
    },
    {
      menuItem: 'صورة من الكاميرا',
      render: () => <Tab.Pane>{imageFromCamera()}</Tab.Pane>,
    },
  ];

  const imageFromDevice = () => {
    return (
      <>
        <Form.Field>
          <Form.Input type='file' id='fileUpload' onChange={fileChangeHandler} error={validationErrors.image} />
        </Form.Field>
      </>
    );
  };

  const imageFromCamera = () => {
    return (
      <>
        {!Boolean(formData.image) ? (
          <div className='camera-div'>
            <Webcam audio={false} ref={webcamRef} screenshotFormat='image/jpeg' className='video-div' />
            <Button fluid onClick={cameraChangeHandler}>
              التقاط صورة
            </Button>
          </div>
        ) : (
          <Button fluid onClick={() => setFormData({ ...formData, image: null })}>
            التقاط صورة جديدة
          </Button>
        )}
      </>
    );
  };

  const checkboxChangeHandler = (id) => {
    let newState = [...customerIdOptions];
    let customer = newState.find((x) => x.id === id);
    customer.isChecked = !customer.isChecked;
    setCustomerIdOptions(newState);
  };

  const departmentsCheckboxChangeHandler = (id) => {
    let newState = [...departmentsIdOptions];
    let department = newState.find((x) => x.id === id);
    department.isChecked = !department.isChecked;
    setDepartmentsIdOptions(newState);
  };

  const groupCheckboxChangeHandler = (group) => {
    let newState = [...customerIdOptions];
    const alreadyChecked = newState.every(
      (customer) => customer.groupName !== group || (customer.groupName === group && customer.isChecked)
    );

    newState.forEach((customer) => {
      if (customer.groupName === group) customer.isChecked = !alreadyChecked;
    });

    setCustomerIdOptions(newState);
  };

  return (
    <PageCardContainer>
      <div style={{ margin: '1rem' }} className='form-margin userForm'>
        <h2 style={{ marginBottom: '2rem' }}>تعديل معلومات الحساب</h2>
        <Form error style={{ margin: '1rem' }} onSubmit={onSubmitHandler} loading={loading}>
          <Form.Group widths={2}>
            <Form.Field>
              <label>اسم المستخدم</label>
              <Form.Input
                icon='user'
                value={formData['userName']}
                name='userName'
                onChange={onChangeHandler}
                error={touched.userName && validationErrors.userName}
              />
            </Form.Field>

            <Form.Field>
              <label>الاسم الكامل</label>
              <Form.Input
                icon='user circle'
                value={formData['fullName']}
                name='fullName'
                onChange={onChangeHandler}
                error={touched.fullName && validationErrors.fullName}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>الهاتف</label>
              <Form.Input
                icon='phone'
                value={formData['phone']}
                name='phone'
                onChange={onChangeHandler}
                error={touched.phone && validationErrors.phone}
              />
            </Form.Field>

            <Form.Field>
              <label>الايميل</label>
              <Form.Input
                icon='mail'
                type='email'
                value={formData['email']}
                name='email'
                onChange={onChangeHandler}
                error={touched.email && validationErrors.email}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>المسمى الوظيفي</label>
              <Dropdown
                placeholder='اختر المسمى الوظيفي'
                fluid
                selection
                search
                value={formData['jobTitle']}
                options={jobTitleOptions}
                onChange={jobSelectionChangeHandler}
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>

            <Form.Field>
              <label>الصلاحيات</label>
              <Dropdown
                placeholder='اختر الصلاحية'
                fluid
                selection
                search
                disabled={!hasPermission(permissions.CHANGE_USER_ROLE)}
                value={formData['role']}
                options={rolesOptions}
                onChange={roleSelectionChangeHandler}
                className='table-dropdown'
                style={{ marginLeft: '0.5rem' }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Popup
              content="عند إلغاء التحديد، تنتهي صلاحية كلمة السر تلقائياً بعد انقضاء فترة الصلاحية المحددة من قبل الآدمن."
              position="top right"
              trigger={
                <Form.Checkbox
                  label='كلمة المرور لا تنتهي صلاحيتها'
                  type='checkbox'
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  name='passwordNeverExpires'
                  disabled={!hasPermission(permissions.PASSWORD_NEVER_EXPIRES)}
                  onChange={(_e, { checked }) => {
                    setFormData({
                      ...formData,
                      passwordNeverExpires: checked,
                    });
                  }}
                  checked={formData['passwordNeverExpires']}
                />
              }
            />

          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>العملاء</label>
              <div className='checkbox-div'>
                {Object.entries(groupBy(customerIdOptions, (x) => x.groupName)).map((group, index) => (
                  <div key={index}>
                    <div className='flex-center'>
                      {group[0] !== 'null' && (
                        <div className='one-checkbox'>
                          <Checkbox
                            name={group[0]}
                            checked={group[1].every((customer) => customer.isChecked)}
                            onClick={() => groupCheckboxChangeHandler(group[0])}
                          />
                        </div>
                      )}
                      <Icon name='group' className='ml1' />
                      <h5>{group[0] !== 'null' ? group[0] : 'بلا مجموعة (غير مصنف)'}</h5>
                    </div>
                    {group[1].map((customer) => (
                      <div key={customer.id} className='one-checkbox mr1'>
                        <Checkbox
                          label={customer.name}
                          name={customer.name}
                          checked={customer.isChecked}
                          onClick={() => checkboxChangeHandler(customer.id)}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </Form.Field>
            <Form.Field>
              <label>الدوائر</label>
              <div className='checkbox-div'>
                {Boolean(departmentsIdOptions.length) ? (
                  departmentsIdOptions.map((department) => (
                    <div key={department.id} className='one-checkbox mr1'>
                      <Checkbox
                        label={department.name}
                        name={department.name}
                        checked={department.isChecked}
                        onClick={() => departmentsCheckboxChangeHandler(department.id)}
                      />
                    </div>
                  ))
                ) : (
                  <h3 className='no-data'>لا يوجد دوائر</h3>
                )}
              </div>
            </Form.Field>
          </Form.Group>

          <h5>صورة المستخدم</h5>
          <Tab panes={panes} />
          {formData.image && (
            <>
              <div className='image-preview-header'>عرض صورة المستخدم الحالية</div>
              <div className='image-preview'>
                <div className='image-cropper'>
                  <Image src={formData.image} className='img' centered />
                </div>
              </div>
            </>
          )}

          {errors.map((item) => (
            <Message error key={item.key} content={item.message} />
          ))}

          <Responsive minWidth={768}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginLeft: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div style={{ marginTop: '2rem' }}>
              <Button
                icon
                fluid
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                className='blue-button'
                labelPosition='right'
                style={{ marginBottom: '1rem' }}
              >
                <Icon name='save' className='blue-buton-icon' />
                حفظ
              </Button>

              <Button
                fluid
                icon='chevron right'
                labelPosition='right'
                content='رجوع'
                onClick={(e) => {
                  e.preventDefault();
                  router.goBack();
                }}
              />
            </div>
          </Responsive>
        </Form>
      </div>
    </PageCardContainer>
  );
}
